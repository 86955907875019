import React, { useState } from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import { CognitoUserPool } from "amazon-cognito-identity-js";

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_APP_CLIENT_ID,
});

function Header({ isLoggedIn, setIsLoggedIn }) {
  const [navOpen, setNavOpen] = useState(false);

  const handleLogout = () => {
    const user = userPool.getCurrentUser();
    if (user) {
      user.signOut();
      setIsLoggedIn(false);
    }
  };

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  const closeNav = () => {
    setNavOpen(false);
  };

  return (
    <header>
      <h1>BrewVault</h1>
      <nav>
        <div className="hamburger" onClick={toggleNav}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={`nav-links ${navOpen ? "active" : ""}`}>
          <span className="close-btn" onClick={closeNav}>
            ×
          </span>
          <Link to="/" onClick={closeNav}>
            Home
          </Link>
          {isLoggedIn ? (
            <>
              <Link to="/bjcpstylesearch" onClick={closeNav}>
                BJCP Search
              </Link>
              <Link to="/meadbacksweetening" onClick={closeNav}>
                Mead Back Sweetening
              </Link>
              <Link to="/colourconverter" onClick={closeNav}>
                Colour Converter
              </Link>
              <Link to="/pressurecalculator" onClick={closeNav}>
                Pressure Calculator
              </Link>
              <Link to="/account" onClick={closeNav}>
                Account
              </Link>
              <Link to="/data-fetcher" onClick={closeNav}>
                Data Fetcher
              </Link>
              <button
                onClick={() => {
                  handleLogout();
                  closeNav();
                }}
              >
                Logout
              </button>
            </>
          ) : (
            <Link to="/login" onClick={closeNav}>
              Login
            </Link>
          )}
        </div>
      </nav>
    </header>
  );
}

export default Header;
