import React, { useState } from 'react';
import { CognitoUserPool, CognitoUser } from 'amazon-cognito-identity-js';

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_APP_CLIENT_ID,
});

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [step, setStep] = useState(1); // 1 for requesting code, 2 for entering code and new password
  const [message, setMessage] = useState('');

  const handleRequestCode = (e) => {
    e.preventDefault();
    const user = new CognitoUser({ Username: email, Pool: userPool });
    user.forgotPassword({
      onSuccess: (data) => {
        setMessage('Verification code sent to email.');
        setStep(2);
      },
      onFailure: (err) => {
        setMessage(`Error: ${err.message}`);
      },
    });
  };

  const handleResetPassword = (e) => {
    e.preventDefault();
    const user = new CognitoUser({ Username: email, Pool: userPool });
    user.confirmPassword(verificationCode, newPassword, {
      onSuccess: () => {
        setMessage('Password reset successful. You can now log in with your new password.');
        setStep(1); // Reset to initial step
      },
      onFailure: (err) => {
        setMessage(`Error: ${err.message}`);
      },
    });
  };

  return (
    <div className="container">
      <h2>Forgot Password</h2>
      {message && <p>{message}</p>}
      {step === 1 ? (
        <form onSubmit={handleRequestCode}>
          <div className="form-group">
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <button type="submit">Request Verification Code</button>
        </form>
      ) : (
        <form onSubmit={handleResetPassword}>
          <div className="form-group">
            <label>Verification Code:</label>
            <input
              type="text"
              value={verificationCode}
              onChange={(e) => setVerificationCode(e.target.value)}
            />
          </div>
          <div className="form-group">
            <label>New Password:</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <button type="submit">Reset Password</button>
        </form>
      )}
    </div>
  );
}

export default ForgotPassword;
