import React from 'react';

function Home() {
  return (
    <div className="home-content">
      <h2>Welcome to the BrewVault</h2>
      <p>If you have an account, please log in.</p>
    </div>
  );
}

export default Home;
