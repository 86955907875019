import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute
} from "amazon-cognito-identity-js";
import AWS from "aws-sdk";

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_APP_CLIENT_ID,
});

export async function login(email, password) {
  const user = new CognitoUser({ Username: email, Pool: userPool });
  const authDetails = new AuthenticationDetails({ Username: email, Password: password });

  return new Promise((resolve, reject) => {
    user.authenticateUser(authDetails, {
      onSuccess: (result) => {
        const { idToken } = result;
        AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: process.env.REACT_APP_USER_POOL_ID,
          Logins: {
            [`cognito-idp.${process.env.REACT_APP_REGION}.amazonaws.com/${process.env.REACT_APP_USER_POOL_ID}`]: idToken.getJwtToken(),
          },
        });
        resolve(result);
      },
      onFailure: (err) => reject(err),
    });
  });
}

export function signUp(email, password) {
  const attributeEmail = new CognitoUserAttribute({
    Name: "email",
    Value: email,
  });

  return new Promise((resolve, reject) => {
    userPool.signUp(email, password, [attributeEmail], null, (err, result) => {
      if (err) {
        reject(err);
      } else {
        resolve(result);
      }
    });
  });
}

export function getCurrentUser() {
  return userPool.getCurrentUser();
}

export function logout() {
  const user = getCurrentUser();
  if (user) {
    user.signOut();
  }
}
