import React, { useState, useEffect } from "react";
import { CognitoUserPool } from "amazon-cognito-identity-js";

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_APP_CLIENT_ID,
});

const DataFetcher = () => {
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState("Conditioning");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [lastUpdated, setLastUpdated] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 25;

  const fetchData = async () => {
    setLoading(true);

    const user = userPool.getCurrentUser();

    if (!user) {
      setError("User not logged in");
      setLoading(false);
      return;
    }

    user.getSession(async (err, session) => {
      if (err) {
        setError("Failed to get user session");
        setLoading(false);
        return;
      }

      user.getUserAttributes(async (err, attributes) => {
        if (err) {
          setError("Failed to get user attributes");
          setLoading(false);
          return;
        }

        let bfUsername = "";
        let bfPassword = "";

        attributes.forEach((attribute) => {
          if (attribute.Name === "custom:BFUsername") {
            bfUsername = attribute.Value;
          }
          if (attribute.Name === "custom:BFPassword") {
            bfPassword = attribute.Value;
          }
        });

        if (!bfUsername || !bfPassword) {
          setError("API Username or Password not found");
          setLoading(false);
          return;
        }

        const credentials = btoa(`${bfUsername}:${bfPassword}`);
        let allData = [];
        let startAfter = null;
        let isEndReached = false;

        while (!isEndReached) {
          let apiUri = `${process.env.REACT_APP_API_URI}`;

          if (startAfter) {
            apiUri += `&start_after=${startAfter}`;
          }

          try {
            const response = await fetch(apiUri, {
              headers: {
                Authorization: `Basic ${credentials}`,
              },
            });

            if (!response.ok) {
              const errorText = await response.text();
              throw new Error(
                `Network response was not ok: ${response.statusText} - ${errorText}`
              );
            }

            const contentType = response.headers.get("content-type");

            if (contentType && contentType.includes("application/json")) {
              const result = await response.json();

              if (result.length === 0) {
                isEndReached = true;
              } else {
                allData = [...allData, ...result];

                if (result.length < 50) {
                  isEndReached = true;
                } else {
                  startAfter = result[result.length - 1]._id;
                }
              }
            } else {
              throw new Error("Response was not JSON");
            }
          } catch (error) {
            console.error("Fetch error:", error);
            setError(error.message);
            setLoading(false);
            return;
          }
        }

        const transformedData = allData.map((item) => ({
          BatchNo: parseInt(item.batchNo, 10),
          brewDate: new Intl.DateTimeFormat("en-AU").format(
            new Date(item.brewDate)
          ),
          name: item.recipe.name,
          status: item.status,
          measuredAbv: parseFloat(item.measuredAbv).toFixed(1),
          estimatedIbu: item.estimatedIbu ?? 0,
          styleCategoryNumber: item.recipe?.style?.categoryNumber ?? "",
          styleStyleLetter: item.recipe?.style?.styleLetter ?? "",
          styleName: item.recipe?.style?.name ?? "",
          tasteRating: parseFloat(item.tasteRating) / 20, // Convert tasteRating from 100 to 5 scale
          _id: item._id,
        }));

        const sortedData = transformedData.sort(
          (a, b) => b.BatchNo - a.BatchNo
        );

        setData(sortedData);
        setFilteredData(
          sortedData.filter((item) => item.status === "Conditioning")
        );
        setStatuses(Array.from(new Set(sortedData.map((item) => item.status))));
        setError(null);
        setLastUpdated(new Date());
      });
    });
    setLoading(false);
  };

  useEffect(() => {
    fetchData();
    const interval = setInterval(() => fetchData(), 30 * 60 * 1000);
    return () => clearInterval(interval);
  }, []);

  const handleFilterChange = (e) => {
    const status = e.target.value;
    setSelectedStatus(status);
    setFilteredData(
      data.filter((item) => status === "" || item.status === status)
    );
    setCurrentPage(1);
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const renderStars = (rating) => {
    rating = isNaN(rating) ? 0 : Math.min(Math.max(rating, 0), 5);
    const fullStars = Math.floor(rating);
    const halfStar = rating - fullStars >= 0.5 ? 1 : 0;
    const emptyStars = 5 - fullStars - halfStar;

    return (
      <>
        {Array(fullStars)
          .fill()
          .map((_, i) => (
            <span key={`full-${i}`} className="fa fa-star checked"></span>
          ))}
        {halfStar === 1 && <span className="fa fa-star-half-o checked"></span>}
        {Array(emptyStars)
          .fill()
          .map((_, i) => (
            <span key={`empty-${i}`} className="fa fa-star-o empty"></span>
          ))}
      </>
    );
  };

  const paginatedData = filteredData.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  return (
    <div className="container">
      <link
        rel="stylesheet"
        href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css"
      />
      <style>
        {`
          .checked {
            color: orange;
          }
          .empty {
            color: orange;
          }
        `}
      </style>
      {loading && <p>Loading...</p>}
      {error && <p>Error: {error}</p>}
      {data.length > 0 && (
        <div>
          <div className="filter">
            <label htmlFor="status">Filter by status: </label>
            <select
              id="status"
              value={selectedStatus}
              onChange={handleFilterChange}
            >
              <option value="">All</option>
              {statuses.map((status, index) => (
                <option key={index} value={status}>
                  {status}
                </option>
              ))}
            </select>
          </div>
          <table className="data-table">
            <thead>
              <tr>
                <th>Batch</th>
                <th>Name</th>
                <th>Brew Date</th>
                <th>ABV %</th>
                <th>IBU</th>
                <th>Rating</th>
              </tr>
            </thead>
            <tbody>
              {paginatedData.map((item, index) => (
                <tr key={index}>
                  <td>{item.BatchNo}</td>
                  <td>
                    <div>{item.name}</div>
                    {item.styleCategoryNumber &&
                      item.styleStyleLetter &&
                      item.styleName && (
                        <div style={{ fontSize: "0.85em", color: "gray" }}>
                          {item.styleCategoryNumber}
                          {item.styleStyleLetter} {item.styleName}
                        </div>
                      )}
                  </td>
                  <td>{item.brewDate}</td>
                  <td>{item.measuredAbv} %</td>
                  <td>{item.estimatedIbu}</td>
                  <td>{renderStars(item.tasteRating)}</td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="pagination">
            {[
              ...Array(Math.ceil(filteredData.length / itemsPerPage)).keys(),
            ].map((pageNumber) => (
              <button
                key={pageNumber}
                onClick={() => handlePageChange(pageNumber + 1)}
                className={pageNumber + 1 === currentPage ? "active" : ""}
              >
                {pageNumber + 1}
              </button>
            ))}
          </div>
          <p className="last-updated">
            Total Records: {filteredData.length} | Last Updated:{" "}
            {lastUpdated &&
              lastUpdated.toLocaleString("en-AU", {
                timeZone: "Australia/Sydney",
              })}
          </p>
        </div>
      )}
    </div>
  );
};

export default DataFetcher;
