import React, { useState, useEffect, useCallback } from "react";
import "../styles.css";

const MeadBackSweetening = () => {
  const [currentGravity, setCurrentGravity] = useState(0.99);
  const [batchSize, setBatchSize] = useState(25.0);
  const [unit, setUnit] = useState("metric");
  const [desiredSweetness, setDesiredSweetness] = useState("dry");
  const [dryValue, setDryValue] = useState(0.99);
  const [semiSweetValue, setSemiSweetValue] = useState(1.01);
  const [sweetValue, setSweetValue] = useState(1.025);
  const [result, setResult] = useState("");

  const formatInput = (value) => parseFloat(value).toFixed(3);

  const calculate = useCallback(() => {
    const formattedCurrentGravity = formatInput(currentGravity);
    const formattedBatchSize = formatInput(batchSize);
    let desiredGravity;

    if (desiredSweetness === "dry") {
      desiredGravity = formatInput(dryValue);
    } else if (desiredSweetness === "semi-sweet") {
      desiredGravity = formatInput(semiSweetValue);
    } else {
      desiredGravity = formatInput(sweetValue);
    }

    let resultValue =
      (((desiredGravity - formattedCurrentGravity) * 1000) / 0.292) *
      formattedBatchSize;

    if (unit === "imperial") {
      resultValue *= 3.785;
    }

    setResult(`Add ${Math.round(resultValue)}g of honey to back sweeten`);
  }, [
    currentGravity,
    batchSize,
    unit,
    dryValue,
    semiSweetValue,
    sweetValue,
    desiredSweetness,
  ]);

  useEffect(() => {
    calculate();
  }, [calculate]);

  const updateSlider = (type, value) => {
    if (type === "dry") {
      setDryValue(value);
    } else if (type === "semi-sweet") {
      setSemiSweetValue(value);
    } else {
      setSweetValue(value);
    }
    calculate();
  };

  const toggleSliders = (e) => {
    setDesiredSweetness(e.target.value);
  };

  return (
    <div className="container">
      <h2>Mead Back-sweetening Calculator</h2>

      <label htmlFor="current-gravity">Current Final Gravity:</label>
      <input
        type="number"
        id="current-gravity"
        value={currentGravity}
        step="0.001"
        onChange={(e) => setCurrentGravity(e.target.value)}
        onBlur={(e) => setCurrentGravity(formatInput(e.target.value))}
      />
      <br />
      <br />

      <div className="group-header">Desired Level of Sweetness</div>

      <div className="combined-slider-container">
        <div className="radio-option">
          <input
            type="radio"
            name="gravity-type"
            id="dry"
            value="dry"
            checked={desiredSweetness === "dry"}
            onChange={toggleSliders}
          />
          <label htmlFor="dry">Dry</label>
        </div>
        <div className="radio-option">
          <input
            type="radio"
            name="gravity-type"
            id="semi-sweet"
            value="semi-sweet"
            onChange={toggleSliders}
          />
          <label htmlFor="semi-sweet">Semi-sweet</label>
        </div>
        <div className="radio-option">
          <input
            type="radio"
            name="gravity-type"
            id="sweet"
            value="sweet"
            onChange={toggleSliders}
          />
          <label htmlFor="sweet">Sweet</label>
        </div>

        {desiredSweetness === "dry" && (
          <div className="slider-container">
            <input
              type="range"
              id="dry-slider"
              min="0.990"
              max="1.010"
              step="0.001"
              value={dryValue}
              onChange={(e) => updateSlider("dry", e.target.value)}
            />
            <span>{dryValue}</span>
          </div>
        )}

        {desiredSweetness === "semi-sweet" && (
          <div className="slider-container">
            <input
              type="range"
              id="semi-sweet-slider"
              min="1.010"
              max="1.025"
              step="0.001"
              value={semiSweetValue}
              onChange={(e) => updateSlider("semi-sweet", e.target.value)}
            />
            <span>{semiSweetValue}</span>
          </div>
        )}

        {desiredSweetness === "sweet" && (
          <div className="slider-container">
            <input
              type="range"
              id="sweet-slider"
              min="1.025"
              max="1.050"
              step="0.001"
              value={sweetValue}
              onChange={(e) => updateSlider("sweet", e.target.value)}
            />
            <span>{sweetValue}</span>
          </div>
        )}
      </div>

      <label htmlFor="batch-size">Batch Size:</label>
      <input
        type="number"
        id="batch-size"
        value={batchSize}
        step="0.001"
        onChange={(e) => setBatchSize(e.target.value)}
        onBlur={(e) => setBatchSize(formatInput(e.target.value))}
      />
      <br />
      <br />

      <div className="radio-container">
        <div>
          <input
            type="radio"
            name="unit"
            id="metric"
            value="metric"
            checked={unit === "metric"}
            onChange={() => setUnit("metric")}
          />
          <label htmlFor="metric" className="radio-label">
            Metric (L)
          </label>
        </div>
        <div>
          <input
            type="radio"
            name="unit"
            id="imperial"
            value="imperial"
            onChange={() => setUnit("imperial")}
          />
          <label htmlFor="imperial" className="radio-label">
            US Imperial (gal)
          </label>
        </div>
      </div>

      <p id="result">{result}</p>
    </div>
  );
};

export default MeadBackSweetening;
