import React, { useState } from 'react';
import '../styles.css'; // Ensure styles.css contains necessary styles

const ColourConverter = () => {
  const [srm, setSrm] = useState('');
  const [lovibond, setLovibond] = useState('');
  const [ebc, setEbc] = useState('');

  const handleSrmChange = (e) => {
    const srmValue = e.target.value;
    setSrm(srmValue);

    if (srmValue === '') {
      setLovibond('');
      setEbc('');
    } else {
      const srmFloat = parseFloat(srmValue);
      setLovibond(((srmFloat + 0.76) / 1.3546).toFixed(1));
      setEbc((srmFloat * 1.97).toFixed(1));
    }
  };

  const handleLovibondChange = (e) => {
    const lovibondValue = e.target.value;
    setLovibond(lovibondValue);

    if (lovibondValue === '') {
      setEbc('');
    } else {
      const srmFloat = (1.3546 * parseFloat(lovibondValue)) - 0.76;
      setSrm(srmFloat.toFixed(1));
      setEbc((srmFloat * 1.97).toFixed(1));
    }
  };

  const handleEbcChange = (e) => {
    const ebcValue = e.target.value;
    setEbc(ebcValue);

    if (ebcValue === '') {
      setSrm('');
      setLovibond('');
    } else {
      const srmFloat = parseFloat(ebcValue) * 0.508;
      setSrm(srmFloat.toFixed(1));
      setLovibond(((srmFloat + 0.76) / 1.3546).toFixed(1));
    }
  };

  return (
    <div className="container">
      <h2>Beer Colour Converter</h2>
      <div>
        <h4>Colour Calculator</h4>
        <div>
          <label htmlFor="ebc">EBC</label>
          <div>
            <input
              type="text"
              placeholder="EBC"
              id="ebc"
              value={ebc}
              onChange={handleEbcChange}
              autoComplete="off"
              step="0.1"
            />
          </div>
        </div>
        <div>
          <label htmlFor="srm">SRM</label>
          <div>
            <input
              type="text"
              placeholder="SRM"
              id="srm"
              value={srm}
              onChange={handleSrmChange}
              autoComplete="off"
              step="0.1"
            />
          </div>
        </div>
        <div>
          <label htmlFor="lovibond">Lovibond</label>
          <div>
            <input
              type="text"
              placeholder="Lovibond"
              id="lovibond"
              value={lovibond}
              onChange={handleLovibondChange}
              autoComplete="off"
              step="0.1"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ColourConverter;
