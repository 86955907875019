import React, { useState, useEffect, useCallback } from 'react';
import '../styles.css'; // Ensure styles.css is properly set up

const PressureCalculator = () => {
  const [concentration, setConcentration] = useState(6.7);
  const [temperature, setTemperature] = useState(4);
  const [pressureBar, setPressureBar] = useState(0);
  const [pressurePsi, setPressurePsi] = useState(0);
  const [unit, setUnit] = useState('g/L');

  const calculatePressure = (C, T, unit) => {
    const baseE = 2.71828182845904; // Base of natural logarithm
    const exponent = -10.73797 + (2617.25 / (T + 273.15));
    const exponentialTerm = Math.pow(baseE, exponent);
    let P;
    
    if (unit === 'g/L') {
      P = (C / (10 * exponentialTerm)) - 1.013;
    } else { // Volumes of CO2
      const gPerL = C * 1.96; // 1 volume of CO2 is approximately 1.96 g/L
      P = (gPerL / (10 * exponentialTerm)) - 1.013;
    }
    
    return P;
  };

  const convertBarToPsi = (bar) => {
    const psi = bar * 14.5038;
    return psi;
  };

  const updatePressure = useCallback(() => {
    const pressure = calculatePressure(concentration, temperature, unit);
    const pressurePsi = convertBarToPsi(pressure);
    setPressureBar(pressure.toFixed(2));
    setPressurePsi(pressurePsi.toFixed(2));
  }, [concentration, temperature, unit]);

  useEffect(() => {
    updatePressure();
  }, [updatePressure]);

  const handleUnitChange = (newUnit) => {
    let newConcentration;
    if (newUnit === 'g/L' && unit === 'volumes') {
      newConcentration = concentration * 1.96; // Convert volumes to g/L
    } else if (newUnit === 'volumes' && unit === 'g/L') {
      newConcentration = concentration * 0.51; // Convert g/L to volumes
    } else {
      newConcentration = concentration;
    }
    setUnit(newUnit);
    setConcentration(parseFloat(newConcentration.toFixed(1)));
  };

  return (
    <div className="container">
      <h2>Pressure Calculator</h2>
      <div style={{ display: 'flex', gap: '10px' }}>
        <label>
          <input
            type="radio"
            value="g/L"
            checked={unit === 'g/L'}
            onChange={() => handleUnitChange('g/L')}
          />
          g/L
        </label>
        <label>
          <input
            type="radio"
            value="volumes"
            checked={unit === 'volumes'}
            onChange={() => handleUnitChange('volumes')}
          />
          Volumes of CO2
        </label>
      </div>
      <div>
        <label htmlFor="concentration">CO2 ({unit}):</label>
        <input
          type="number"
          id="concentration"
          step="0.1"
          min="0"
          value={concentration}
          onChange={(e) => setConcentration(parseFloat(e.target.value))}
        />
      </div>
      <div>
        <label htmlFor="temperature">Temperature (°C):</label>
        <input
          type="number"
          id="temperature"
          step="0.1"
          value={temperature}
          onChange={(e) => setTemperature(parseFloat(e.target.value))}
        />
      </div>
      <p>
        Pressure: <span id="pressureOutput">{pressureBar}</span> bar / <span id="pressureOutputPsi">{pressurePsi}</span> psi
      </p>
    </div>
  );
};

export default PressureCalculator;
